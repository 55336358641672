import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import { createPinia } from 'pinia';
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import mitt from 'mitt';
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br'; // import locale
import Maska from 'maska';
import money from 'v-money';

dayjs.locale('pt-br') 

const emitter = mitt();
const app = createApp(App);
const pinia = createPinia();

app.directive('cancelreadonly', (el) => {
    const input = el.querySelector('.el-input__inner');
    input.removeAttribute('readonly');
})
  
app.config.globalProperties.emitter = emitter;

app.use(store);
app.use(router);
app.use(pinia);
app.use(money);
app.use(Maska);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
// @ts-ignore
app.use(ElementPlus);
app.mount("#app");