import AuthService from "@/services/AuthService";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Gard from "@/services/Middleware";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: [Gard.authenticateuser],

    children: [
      {
        path: "/projects",
        name: "projects",
        components:{
          default: () => import("@/views/projects/Projects.vue"),
        },
      },
      {
        path: "/tickets",
        name: "tickets",
        components:{
          default: () => import("@/views/tickets/Tickets.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        path: "/tickets/:id",
        name: "ticketsId",
        components:{
          default: () => import("@/views/tickets/Tickets.vue"),
          // filter: () => import('@/layout/header/partials/filters/FilterDashboard.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        path: "/listaTickets",
        name: "listaTickets",
        components:{
          default: () => import("@/views/tickets/TicketsList.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterAttendance.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/listaTicketsImplantacoes",
        name: "listaTicketsImplantacoes",
        components:{
          default: () => import("@/views/tickets/TicketsList.vue"),
          filter: () => import('@/layout/header/partials/filters/FilterAttendance.vue')
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/createFormTickets",
        name: "createFormTickets",
        components:{
          default: () => import("@/views/formCreateTicket/FormCreateTicket.vue"),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/users",
        name: "users",
        components:{
          default: () => import("@/views/usersEdit/Users.vue"),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/projectsList",
        name: "projectsList",
        components:{
          default: () => import("@/views/projects/ProjectsList.vue"),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
      {
        // the 401 route, when none of the above matches
        path: "/alertSound",
        name: "alertaSound",
        components:{
          default: () => import("@/views/alertSound/AlertSound.vue"),
        },
        meta : {nivelCargo : [100, 150, 650]},
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Login.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
        beforeEnter: [Gard.redirectAuthenticatedUser],
      },
    ],
  },
  {
    // the 401 route, when none of the above matches
    path: "/401",
    name: "401",
    component: () => import("@/views/Error401.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  document.body.classList.add("page-loading");
  document.body.classList.remove("modal-open");
  document.body.style.overflow = "auto";
  document.body.style.paddingRight = "0";
  

  // Evita o loop na chamada
  if (to.path !== '/login') {
    // AuthService.destroyUsuarioAuth()
    try {
      const usuario = JSON.parse(AuthService.getUsuario()) ;
      if(!usuario){
        throw new Error('User not found')
      }
    } catch (error) { // Caso ocorra algum erro na validação o usuário será deslogado.
      console.log('[ERRO]', error);
      store.dispatch(Actions.LOGOUT);
    }
    
  }


  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from) => {
  document.body.classList.remove("page-loading");
});

export default router;
