import { Mutations } from "@/store/enums/StoreEnums";
import { UsuarioModel } from "@/models/UsuarioModel";
import { ResponseReq } from "@/models/ResponseReq";
import ApiService from "./ApiService";

const USER = "user" as string;
const PROJECTS = "projects" as string;
const TOKEN_USER = "token_usuario" as string;
const ID_PROJECT = "id_project" as string;

export const logarUsuario = (credentials): any => {
  return ApiService.post("auth", credentials);
};

/**
 * @description get usuario form localStorage
 *
 *
 */

export const getUsuario = (): any => {
  const user: any = window.localStorage.getItem(USER);
  return user;
};

export const getProjects = (): any => {
  const projects: any = JSON.parse(window.localStorage.getItem(PROJECTS) as string);
  return projects;
};
export const getIdProject = (): any => {
  const id_project: any = JSON.parse(window.localStorage.getItem(ID_PROJECT) as string);
  return id_project;
};

export const getUsuarioAuth = (): UsuarioModel => {
  return {} as UsuarioModel;
};

/**
 * @description save usuario into localStorage
 * @param usuario: UsuarioModel
 */
export const saveUsuarioAuth = async (usuario: UsuarioModel) => {
  const user = usuario;
  const dadosSensiveis = ["cpf", "email_verified_at", "password"];
  await dadosSensiveis.forEach((dados) => {
    delete user[dados];
  });

  window.localStorage.setItem(USER, JSON.stringify(user));
};

export const saveProjects = async (projects) => {
  window.localStorage.setItem(PROJECTS, JSON.stringify(projects));
};

/**
 * @description remove usuario form localStorage
 */
export const destroyUsuarioAuth = (): void => {
  window.localStorage.removeItem(USER);
  window.localStorage.removeItem(TOKEN_USER);
  window.localStorage.removeItem(PROJECTS);
  window.localStorage.removeItem(ID_PROJECT);
};

export default {
  getUsuarioAuth,
  saveUsuarioAuth,
  destroyUsuarioAuth,
  logarUsuario,
  getUsuario,
  saveProjects,
  getProjects,
  getIdProject
};
